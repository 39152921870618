.image-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.image-container img {
    max-width: 300px;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.2s;
}

.image-container img:hover {
    transform: scale(1.05);
}

.image-caption {
    text-align: center;
    font-style: italic;
}

/* src/components/ImageGallery.css */
.image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin: 20px 0;
  }
  
  .image-item {
    cursor: pointer;
    overflow: hidden;
  }
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease;
  }
  
  .image-item:hover img {
    transform: scale(1.05);
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background: white;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal-content img {
    width: 100%;
    height: auto;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    color: black;
    cursor: pointer;
  }
  