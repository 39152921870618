.slider-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #5597e8;
  }
  
  .slider-content {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .slider-item {
    min-width: 200px;
    height: 200px;
    background-color: #ccc;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }